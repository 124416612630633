<template>
  <div
    class="banner flex items-center justify-between mb-8 bg-white p-4 "
    :class="borderBottomClass"
  >
    <i
      v-if="icon"
      class="flex-shrink-0 material-icons micon text-xl mr-4 "
      :class="{ 'tozny-theme-font-color': isToznyThemeType }"
      >{{ icon }}</i
    >
    <div
      class="flex-shrink flex-grow"
      :class="{ 'tozny-theme-font-color': isToznyThemeType }"
    >
      <slot></slot>
    </div>
    <ToznyButton
      v-if="buttonText"
      class="banner-button mb-0 ml-4 focus:outline-none active:outline-none"
      :class="buttonClass"
      :buttonText="buttonText"
      @click.native="clickMethod"
    />
  </div>
</template>

<script>
import ToznyButton from '@/Common/ToznyButton'

export default {
  name: 'Banner',
  props: {
    title: String,
    text: String,
    buttonText: String,
    type: {
      type: String,
      default: 'tozny-theme',
    },
    icon: String,
    clickMethod: Function,
  },
  components: {
    ToznyButton,
  },
  computed: {
    borderBottomClass: function() {
      return {
        'red-accent': this.type === 'red',
        'blue-accent': this.type === 'blue',
        'green-accent': this.type === 'green',
        'tozny-theme-banner': this.type === 'tozny-theme',
      }
    },
    isToznyThemeType: function() {
      return this.type === 'tozny-theme'
    },
    buttonClass: function() {
      return {
        'red-button': this.type === 'red',
        'blue-button': this.type === 'blue',
        'green-button': this.type === 'green',
        'tozny-theme-button': this.type === 'tozny-theme',
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/scss/variables.scss';

.banner {
  box-shadow: 0 0 5px 0 rgba(232, 232, 232, 0.53);
  border-bottom: 2px solid;
}

.red-accent {
  border-color: $theme-red;
}

.blue-accent {
  border-color: $theme-blue;
}
.green-accent {
  border-color: $theme-green;
}

.red-button:hover {
  color: $white;
  background-color: $theme-red;
  border-radius: 6px;
}

.red-button:active {
  border-radius: 6px;
  background-color: $theme-red-active;
}

.blue-button:hover {
  color: $white;
  background-color: $theme-blue;
  border-radius: 6px;
}

.blue-button:active {
  border-radius: 6px;
  background-color: $theme-blue-active;
}

.green-button:hover {
  color: $white;
  background-color: $theme-green;
  border-radius: 6px;
}

.green-button:active {
  border: 2px solid $theme-green-active;
  border-radius: 6px;
  background-color: $theme-green-active;
}

.tozny-theme-banner {
  background-color: $theme-green;
  border-color: $theme-green-border;
}
.tozny-theme-font-color {
  color: $white;
}
.tozny-theme-button {
  border: 2px solid $white;
  background: $theme-green;
}

.tozny-theme-button:hover {
  background: $white;
  color: $theme-green;
  border: 2px solid $theme-green-active;
  border-radius: 6px;
}

.tozny-theme-button:active {
  border: 2px solid $theme-green-active;
  border-radius: 6px;
  background-color: $theme-green-active;
  color: $white;
}
</style>
