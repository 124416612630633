<template>
  <AuthenticationLayout id="view-recovery-complete" v-if="complete">
    <Banner type="green" button-text="Sign In" :click-method="toSignIn">
      <p class="mb-0">Recovery complete!</p>
    </Banner>
  </AuthenticationLayout>
  <AuthenticationLayout id="view-recovery-error" v-else-if="generalError !== ''">
    <Banner type="red" button-text="Return" :click-method="toSignIn">
      <p class="mb-0">{{ generalError }}</p>
    </Banner>
  </AuthenticationLayout>
  <AuthenticationForm id="view-set-password" v-else-if="identity">
    <div class="py-4 px-4 sm:px-8">
      <h3 class="text-base font-semibold mb-6 text-center">
        Set New Password
      </h3>
      <ToznyForm
        :error="formError"
        :loading="loading"
        :onSubmit="btnRecover"
        class="login-form"
      >
        <ToznyInput
          v-model="newPassword"
          type="password"
          id="password"
          name="password"
          class="mb-4 md:text-lil"
          label="Password"
          float-label
        />
        <ToznyInput
          v-model="confirmPassword"
          type="password"
          id="confirm-password"
          name="password"
          class="mb-6 md:text-lil"
          label="Confirm Password"
          float-label
        />
        <ToznyButton
          id="login-button"
          class="login-button w-full py-3 rounded-lg text-lil"
          buttonText="Set Password"
        />
      </ToznyForm>
      <router-link class="text-tozny no-underline text-tiny" to="login"
        >Back to Sign In</router-link
      >
    </div>
  </AuthenticationForm>
</template>

<script>
import { mapGetters } from 'vuex'

import AuthenticationForm from '@/Common/AuthenticationFrom/AuthenticationForm'
import AuthenticationLayout from '@/Common/AuthenticationFrom/AuthenticationLayout'
import Banner from '@/Common/Banner'
import ToznyForm from '@/Common/ToznyForm'
import ToznyButton from '@/Common/ToznyButton'
import ToznyInput from '@/Common/ToznyInput'
import { validatePassword } from '@/utils/utils'

export default {
  name: 'RecoverPassword',
  components: {
    AuthenticationForm,
    AuthenticationLayout,
    Banner,
    ToznyForm,
    ToznyButton,
    ToznyInput,
  },
  data: function() {
    return {
      newPassword: '',
      confirmPassword: '',
      generalError: '',
      displayError: '',
      identity: false,
      loading: false,
      complete: false,
    }
  },
  computed: {
    ...mapGetters(['realm']),
    formError: function() {
      if (this.validatePasswordError) {
        return this.validatePasswordError
      } else if (this.matchingPasswordError) {
        return this.matchingPasswordError
      }
      return this.displayError
    },
    validatePasswordError: function() {
      if (this.newPassword) {
        return validatePassword(this.newPassword)
      }
      return ''
    },
    matchingPasswordError: function() {
      if (this.newPassword !== this.confirmPassword) {
        return 'Passwords do not match'
      }
      return ''
    },
  },
  methods: {
    async btnRecover() {
      this.loading = true
      if (
        !this.newPassword ||
        this.validatePasswordError ||
        this.matchingPasswordError
      ) {
        this.loading = false
        return
      }
      try {
        await this.identity.changePassword(this.newPassword)
      } catch (e) {
        this.loading = false
        this.displayError = `Error updating password: ${e.message}`
        return
      }
      this.loading = false
      this.complete = true
    },
    toSignIn() {
      this.$router.replace('login')
    },
  },
  async mounted() {
    try {
      const noteID = this.$route.query.note_id
      if (!noteID) {
        throw new Error('Missing recovery ID')
      }
      if (this.$route.query.email_otp) {
        this.identity = await this.realm.completeEmailRecovery(
          this.$route.query.email_otp,
          noteID
        )
      } else if (this.$route.query.tozny_otp) {
        this.identity = await this.realm.toznyRecovery(
          this.$route.query.tozny_otp,
          noteID
        )
      } else {
        throw new Error('Missing recovery token')
      }
    } catch (e) {
      switch (e.name) {
        case 'InvalidCredentials':
          this.generalError =
            'Unable to validate recovery information. Please try again.'
          break
        case 'CredentialNoteError':
          this.generalError =
            'We were unable to recover your account at this time. ' +
            'This is likely a problem on our side. Try again later' +
            'If the problem persists, please contact your administrator.'
          break
        case 'CredentailNoteData':
          this.generalError =
            'There was an error in your recovery data. Please ' +
            'contact your administrator to continue.'
          break
        default:
          this.generalError = `Unable to recover identity: ${e.message}`
          break
      }
    }
  },
}
</script>
